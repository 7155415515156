import { useState, useEffect, useRef } from "react";
 
const PrivacyTab = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isSidebarFixed, setIsSidebarFixed] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 990);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const observerRef = useRef(null);

  const sections = [
    { id: "intro", title: "Introduction" },
    { id: "info", title: "Information We Collect" },
    { id: "use", title: "How We Use Your Information" },
    { id: "security", title: "Data Security" },
    { id: "privacy", title: "Cookie Policy" },
    { id: "sharing", title: "Sharing Your Information" },
    { id: "changes", title: "Changes to This Privacy Policy" },
    { id: "contact", title: "Contact Us" },
  ];

  const handleScroll = () => {
    const contentBottom = contentRef.current.getBoundingClientRect().bottom;
    const viewportHeight = window.innerHeight;
    const sidebarHeight = sidebarRef.current.offsetHeight;
  
    if (!isMobileView) {
      if (contentBottom <= sidebarHeight + 100) {
        setIsSidebarFixed(false);
    } else {
        setIsSidebarFixed(true);
    }
    }
  
    if (isMobileView && sidebarRef.current) {
      const sidebarTop = sidebarRef.current.getBoundingClientRect().top;
  
      if (contentBottom <= viewportHeight) {
        sidebarRef.current.style.position = "relative";
        sidebarRef.current.style.bottom = "0";
        sidebarRef.current.style.top = "auto";
      } else {
        sidebarRef.current.style.position = "fixed";
        sidebarRef.current.style.top = "100px";
        sidebarRef.current.style.bottom = "auto";
      }
    }
  
    let closestSection = null;
    let smallestOffset = Infinity;
  
    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetFromTop = Math.abs(rect.top - 100);
  
        if (rect.top >= 0 && offsetFromTop < smallestOffset) {
          closestSection = section.id;
          smallestOffset = offsetFromTop;
        }
      }
    });
  
    setActiveSection(closestSection);

    if (isMobileView && closestSection) {
      const activeElement = document.querySelector(`.sidebar a.active`);
      if (activeElement) {
        const activeElementLeft = activeElement.offsetLeft;
        sidebarRef.current.scrollTo({
          left: activeElementLeft - 20,
          behavior: 'smooth',
        });
      }
    }
    
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView,activeSection]);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 990);
  };

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-50% 0px -50% 0px", 
      threshold: 0.1,
    });

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        observerRef.current.observe(element);
      }
    });

    window.addEventListener("resize", handleResize);

    return () => {
     
      observerRef.current.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [sections]);
  return (
    <div className="sidetab-main">
      <div className="container">
      <div className="terms-and-conditions">
        <div className={`sidebar-wrapper ${isMobileView ? "mobile" : ""}`}>
          <div
            ref={sidebarRef}
            className={`sidebar ${!isSidebarFixed && !isMobileView ? "scrolling" : ""}`}
          >
            {sections.map((section) => (
              <a
                key={section.id}
                onClick={() => {
                  const targetElement = document.getElementById(section.id);
                  if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                  }
                }}
                className={activeSection === section.id ? "active" : ""}
              >
                {section.title}
              </a>
            ))}
          </div>
        </div>
   <div ref={contentRef} className="content1">
            <p className="title1">Privacy Policy</p>
            <div className="content-section" id="intro">
              <h2>Introduction</h2>
              <p>Welcome to Althi Solutions. We are committed to safeguarding your privacy and protecting your personal information. This Privacy & Cookie Policy outlines how we collect, use, disclose, and protect your personal information when you interact with our website, products, and services. By using our website and services, you consent to the practices described in this policy.</p>
            </div>
            <div className="content-section" id="info">
              <h2>Information We Collect</h2>
              <p>We may collect and process the following types of personal information:
                 <ul>
                  <li><strong>Contact Information:</strong> Includes your name, email address, phone number, and postal address, collected when you communicate with us or utilize our services.</li>
                  <li><strong>Usage Information:</strong> Information regarding your interaction with our website and services, such as IP addresses, device information, browser type, and pages visited, to help us improve our services and tailor your user experience.</li>
                  <li><strong>Account Information:</strong> If you create an account with us, we collect and store information such as your username, password, and preferences.</li>
                  </ul>
                  </p>
            </div>
            <div className="content-section" id="use">
              <h2>How We Use Your Information</h2>
              <p>We use your personal information for the following purposes:
              <ul>
                  <li><strong>Providing Services:</strong> To deliver the products and services you request, including processing orders and providing customer support.</li>
                  <li><strong>Improving Services:</strong> We may analyze usage data to enhance our website and services, personalize content based on your preferences, and develop new features.</li>
                  <li><strong>Communication:</strong> Your contact information may be used to send you updates, promotional offers, newsletters, and important announcements. You may opt out of these communications at any time.</li>
                  <li><strong>Legal Compliance:</strong> We may process your data to comply with legal obligations and respond to lawful requests from government authorities.</li>
                  </ul>
                  </p>
            </div>
            <div className="content-section" id="security">
              <h2>Data Security</h2>
              <p>We are committed to protecting your personal information. Althi Solutions employs industry-standard security measures to secure your data against unauthorized access, disclosure, alteration, and destruction. Please note that no data transmission method over the Internet is entirely secure, and we cannot guarantee the absolute security of your information.</p>
            </div>
            <div className="content-section" id="privacy">
              <h2>Cookie Policy</h2>
              <p>Our website uses cookies to enhance your experience, analyze site usage, and provide customized content. Cookies are small data files stored on your device. You can manage your cookie preferences through your browser settings, which allow you to block or delete cookies. However, please note that disabling certain cookies may impact your experience on our site.</p>
            </div>
            <div className="content-section" id="sharing">
              <h2>Sharing Your Information</h2>
              <p>We do not sell your personal information to third parties. However, we may share your data with trusted third parties under these conditions:
              <ul>
                  <li><strong>Service Providers:</strong> We may share information with trusted third-party providers who assist us in operating our website and delivering our services.</li>
                  <li><strong>Legal Requirements:</strong>We may disclose your information to comply with legal obligations or to protect our rights, privacy, safety, property, and those of our clients and the public.</li>
                  </ul>
              </p>
            </div>
            <div className="content-section" id="changes">
              <h2>Changes to This Privacy Policys</h2>
              <p>We may update this Privacy Policy periodically to reflect changes in our practices or for legal purposes. We encourage you to review this policy periodically.</p>
            </div>
            <div className="content-section" id="contact">
              <h2>Contact Us</h2>
              <p>If you have any questions, concerns, or requests related to your personal information or this Privacy Policy, please contact us at info@althisolutions.com.</p>
            </div>
           
          </div>
        </div>
        </div>
      </div>
    )
}
 
export default PrivacyTab;