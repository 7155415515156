import React from "react";
import "./OurClients.scss";
import Woody from "../../Media/woody.png";
import Alnada from "../../Media/alnada.png";
import aryanow from "../../Media/aryanow.png";
import Kaladi from "../../Media/kaladi.png";
import phi from "../../Assets/Home/phi.svg";
import micro from "../../Assets/Home/micro.svg";
import Slider from "react-slick";

const OurClients = () => {
    const images = [
        {
            image: Kaladi,
        },
        {
            image: Alnada,
        },
        {
            image: Woody,
        },
        {
            image: phi,
        },
        {
            image: aryanow,
        },
        {
            image: micro,
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 8000,
        autoplaySpeed: 8000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <div className="client-section">
                <div className="container">
                <button className="ourClients-btn">Our Partners</button>
                <h2 className="ourClients-title">We’re Working With</h2>
                </div>
                <div className="ourClients">
                  
                    <Slider {...settings}>
                        {images.map((image) => (
                            <img className="ourClients-images" src={image.image} alt="" />
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default OurClients;
