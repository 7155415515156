import { useState, useEffect, useRef } from "react";
import "./SideTab.scss";

const SideTab = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isSidebarFixed, setIsSidebarFixed] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 990);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const observerRef=useRef(null);
  const sections = [
    { id: "acceptance", title: "Acceptance of Terms",},
    { id: "use", title: "Use of the Website", },
    { id: "services", title: "Services and Limitations" },
    { id: "intellectual", title: "Intellectual Property" },
    { id: "privacy", title: "Privacy Policy" },
    { id: "links", title: "Links to Third-Party Websites" },
    { id: "disclaimer", title: "Disclaimer of Warranties" },
    { id: "liability", title: "Limitation of Liability" },
    { id: "responsibilities", title: "User Responsibilities" },
    { id: "changes", title: "Changes to Terms and Conditions" },
    { id: "governing", title: "Governing Law and Jurisdiction" },
    { id: "contact", title: "Contact Information" },
    { id: "forceMajeure", title: "Force Majeure" },
    { id: "indemnification", title: "Indemnification" },
  ];

  const handleScroll = () => {
    const contentBottom = contentRef.current.getBoundingClientRect().bottom;
    const viewportHeight = window.innerHeight;
    const sidebarHeight = sidebarRef.current.offsetHeight;
  
    if (!isMobileView) {
      setIsSidebarFixed(contentBottom > sidebarHeight + 100);
    }
  
    if (isMobileView && sidebarRef.current) {
      const sidebarTop = sidebarRef.current.getBoundingClientRect().top;
  
      if (contentBottom <= viewportHeight) {
        sidebarRef.current.style.position = "relative";
        sidebarRef.current.style.bottom = "0";
        sidebarRef.current.style.top = "auto";
      } else {
        sidebarRef.current.style.position = "fixed";
        sidebarRef.current.style.top = "100px";
        sidebarRef.current.style.bottom = "auto";
      }
    }
  
    let closestSection = null;
    let smallestOffset = Infinity;
  
    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetFromTop = Math.abs(rect.top - 100);
  
        if (rect.top >= 0 && offsetFromTop < smallestOffset) {
          closestSection = section.id;
          smallestOffset = offsetFromTop;
        }
      }
    });
  
    setActiveSection(closestSection);

    if (isMobileView && closestSection) {
      const activeElement = document.querySelector(`.sidebar a.active`);
      if (activeElement) {
        const activeElementLeft = activeElement.offsetLeft;
        sidebarRef.current.scrollTo({
          left: activeElementLeft - 20,
          behavior: 'smooth',
        });
      }
    }
    
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView,activeSection]);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 990);
  };

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-50% 0px -50% 0px", 
      threshold: 0.1,
    });

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        observerRef.current.observe(element);
      }
    });

    window.addEventListener("resize", handleResize);

    return () => {
     
      observerRef.current.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [sections]);
  return (
    <div className="sidetab-main">
      <div className="container">
      <div className="terms-and-conditions">
        <div className={`sidebar-wrapper ${isMobileView ? "mobile" : ""}`}>
          <div
            ref={sidebarRef}
            className={`sidebar ${!isSidebarFixed && !isMobileView ? "scrolling" : ""}`}
          >
            {sections.map((section) => (
              <a
                key={section.id}
                onClick={() => {
                  const targetElement = document.getElementById(section.id);
                  if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                  }
                }}
                className={activeSection === section.id ? "active" : ""}
              >
                {section.title}
              </a>
            ))}
          </div>
        </div>
        <div ref={contentRef} className="content1">
          <p className="title1">Terms And Conditions</p>
          <div className="content-section" id="acceptance">
            <h2>Acceptance of Terms</h2>
            <p>By accessing and using our website (althisolutions.com) or any associated platforms, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, you are advised to refrain from using the Website.</p>
          </div>
          <div className="content-section" id="use">
            <h2>Use of the Website</h2>
            <p><b>2.1. License:</b> Althi Solutions grants you a limited, non-exclusive, and revocable license to access and use the Website for informational purposes related to our products and services.
                  <br/>
               <b> 2.2. Prohibited Activities:</b> By using this Website, you agree not to:
               <ul>
                <li>Violate any applicable laws or regulations.</li>
                <li>Attempt to gain unauthorized access to the Website, its servers, or any connected systems.</li>
                <li>Use the Website for illegal, unethical, or fraudulent purposes.</li>
                <li>Transmit any malicious software, harmful code, or engage in activities that could compromise the Website's security.</li>
                <li>Misrepresent your association with Althi Solutions or falsely claim access to proprietary information.</li>
                </ul>
                </p>
          </div>
          <div className="content-section" id="services">
            <h2>Services and Limitations</h2>
            <p>
            <b>3.1. Scope of Services:</b>
                Althi Solutions provides consulting services and software solutions such as<b> Acaia-AML, Exaan-Remittance,</b> and<b> VCheck-Antivirus.</b> Access to our digital services may be subject to additional agreements and service-specific terms.
                <br/>
            <b>3.2. Service Limitations:</b>
                While we strive to ensure uninterrupted availability of our website and services, Althi Solutions does not guarantee uninterrupted, error-free operation and reserves the right to modify, restrict, or terminate service access without notice.
            </p>
          </div>
          <div className="content-section" id="intellectual">
            <h2>Intellectual Property</h2>
            <p><b>4.1. Copyright:</b> All content on the Website, including but not limited to text, images, graphics, logos, videos, and software, is the property of Althi Solutions and is protected under copyright laws. Any unauthorized use, reproduction, or distribution of content is strictly prohibited.
                  <br/>
                <b>4.2. Trademarks:</b> All trademarks, service marks, and logos displayed on this Website are the exclusive property of Althi Solutions or their respective owners. Use of these trademarks without written permission is prohibited.</p>
          </div>
          <div className="content-section" id="privacy">
            <h2>Privacy Policy</h2>
            <p>Your use of the Website is also governed by our Privacy Policy, which outlines how we collect, use, and safeguard your personal information.</p>
          </div>
          <div className="content-section" id="links">
            <h2>Links to Third-Party Websites</h2>
            <p>The Website may include links to third-party websites. Althi Solutions is not responsible for the accuracy, reliability, or content of these external sites. Visiting these sites is at your own discretion and risk.</p>
          </div>
          <div className="content-section" id="disclaimer">
            <h2>Disclaimer of Warranties</h2>
            <p>7.1. The Website and its contents are provided "as is" and "as available," without warranties of any kind, either express or implied. This includes but is not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                <br/>
              7.2. Althi Solutions does not guarantee the accuracy, reliability, or completeness of any information, tools, or resources provided on the Website.</p>
          </div>
          <div className="content-section" id="liability">
            <h2>Limitation of Liability</h2>
            <p>Under no circumstances shall Althi Solutions be held liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use the Website or our services, including but not limited to loss of revenue, data, or reputation.</p>
          </div>
          <div className="content-section" id="responsibilities">
            <h2>User Responsibilities</h2>
            <p>
            <b>9.1. Account Security:</b> If you register for an account on the Website, you are responsible for maintaining the confidentiality of your login credentials and are liable for all activities conducted under your account.
              <br/>
            <b>9.2. Accuracy of Information:</b> You agree to provide accurate, current, and complete information when using the Website or interacting with Althi Solutions.
            </p>
          </div>
          <div className="content-section" id="changes">
            <h2>Changes to Terms and Conditions</h2>
            <p>Althi Solutions reserves the right to update, modify, or replace these Terms and Conditions at any time. Any changes will be effective immediately upon posting. It is your responsibility to review these Terms periodically.</p>
          </div>
          <div className="content-section" id="governing">
            <h2>Governing Law and Jurisdiction</h2>
            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the<b> United States of America.</b> Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of the<b> United States.</b></p>
          </div>
          <div className="content-section" id="contact">
            <h2>Contact Information</h2>
            <p>For any questions or concerns regarding these Terms and Conditions, you can contact us at:<br/>
                <b>Email:</b> info@althisolutions.com<br/>
                 <b>Phone:</b> +1 959-666-3350</p>
          </div>
          <div className="content-section" id="forceMajeure">
            <h2>Force Majeure</h2>
            <p>Althi Solutions shall not be held liable for delays or failures in performance resulting from causes beyond our reasonable control, including acts of God, government actions, natural disasters, or technical disruptions.</p>
          </div>
          <div className="content-section" id="indemnification">
            <h2>Indemnification</h2>
            <p>You agree to indemnify and hold harmless Althi Solutions, its affiliates, employees, and agents from any claims, liabilities, damages, or expenses arising from your violation of these Terms or misuse of the Website.</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SideTab;
