import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import "./BlogSection.scss";
import { usePosts } from '../../custom-hooks';

const BlogSection = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(6);

    const [blogs,] = usePosts();

    const sortedBlogs = blogs
        .sort((a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt))
        .slice(first, first + rows);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    return (
        <>
        {/* <div className='container'> */}
            <div className="blog-content-section">
                
                <div className="grid m-0 p-0">
                    <div className="col-12 m-0 p-0">
                        <Card className="p-3 flex flex-column blog-card-section flex-wrap">
                            <div className='container'>
                            <div>
                                <h2 className="blog-section-heading">Althi Blog</h2>
                            </div>

                            <div className="flex align-items-center card-content">
                                {sortedBlogs.map((blog, index) => (
                                    <div key={index} className="col-4 lg:col-4 col-section">
                                        <div className="m-3">
                                            <Card className="new">
                                                <div className="blog-img" >
                                                    <img src={blog.fields.blogImage[0].fields.file.url} alt={blog.fields.blogTitle} />
                                                </div>

                                                <div className="blog-text">
                                                    <div className="pt-3">
                                                        <span >{blog.fields.blogAuthor} | {blog.fields.createdDate}</span> <br />
                                                    </div>

                                                    <div className="pt-3">
                                                        <p className="blogs-heading">{blog.fields.blogTitle}
                                                            <a href={`/blog/${blog.sys.id}`} aria-label="Read more">
                                                                <i className="pi pi-arrow-right" />
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>

                                            </Card>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {blogs.length > rows && (
                                <Paginator
                                    first={first}
                                    rows={rows}
                                    totalRecords={blogs.length}
                                    rowsPerPageOptions={[6, 12, 18]}
                                    onPageChange={onPageChange}
                                    template="PrevPageLink PageLinks NextPageLink"
                                />
                            )}
                            </div>
                        </Card>
                    </div>
                </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default BlogSection;
