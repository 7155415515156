import React, { useState, useEffect } from "react";
import "./Founder.scss";
import Ashish from "../../Assets/Home/Ashish1.png";
import Wilson from "../../Assets/Home/Wilson1.png";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa6";
import { AiOutlineInstagram } from "react-icons/ai";

const profiles = [
    {
        name: "Wilson Raj",
        role: "Founder",
        bio: `With a strong background in system architecture and cloud infrastructure, John leads our team in
        building scalable, secure solutions. Over 12 years, he has established himself as a visionary in cloud 
        technologies, driving the company to adopt cutting-edge solutions for complex problems.`,
        image: Wilson,
    },
    {
        name: "Ashish Nadar",
        role: "Co Founder",
        bio: `The titan of transformative business possesses a wealth of experience spanning nearly 30+ years 
        in business management. With an impressive track record in running schools and managing multiple business, 
        he brought a unique perspective to the world of technology. His commitment and positive attitude continue to 
        shape our company's success, making him an inspiring leader in the software industry.`,
        image: Ashish,
    },
];

const Founder = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const upcomingIndex = currentIndex === 0 ? 1 : 0;

    return (
        
        <div className="founder-container">
            <div className="container">
            <div className="founder-section">
                <div className="founder-details">
                    <span className="role-tag">{profiles[currentIndex].role}</span>
                    <h2 className="founder-name">{profiles[currentIndex].name}</h2>
                    <p className="founder-bio">{profiles[currentIndex].bio}</p>
                    <div className="social-icons-sec">
                        <a
                            href="https://www.facebook.com/AlthiSolutions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ImFacebook />
                        </a>
                        <a
                            href="https://in.linkedin.com/company/althisolutions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedinIn />
                        </a>
                        <a
                            href="https://www.instagram.com/althi_solutions/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <AiOutlineInstagram />
                        </a>
                    </div>
                </div>

                <div className="image-container">
                    <img
                        className="current-image"
                        src={profiles[currentIndex].image}
                        alt={profiles[currentIndex].name}
                    />
                    <img
                        className="upcoming-image"
                        src={profiles[upcomingIndex].image}
                        alt={profiles[upcomingIndex].name}
                    />
                </div>
            </div>
        </div>
        </div>
    );
};

export default Founder;
